import { forwardRef } from 'react';

import { Button, ButtonProps } from '@blockworks/ui/button';

type StudioButtonProps = ButtonProps;

const StudioButton = forwardRef<HTMLButtonElement, StudioButtonProps>(function StudioButton(props, ref) {
    return <Button size="2sm" borderRadius="md" {...props} ref={ref} />;
});

export type { StudioButtonProps };
export { StudioButton };
