import { UserDropdown } from '@studio/components/common/user-dropdown';
import { StudioRoutePath } from '@studio/models/routes';
import { useRouter } from 'next/router';

import { Bit } from '@blockworks/ui/bit';
import { FlexBox, Menu } from '@blockworks/ui/components';
import { CodeIcon, DashboardIcon, QueriesIcon } from '@blockworks/ui/icon';

import { CreateEntityPopover } from './create-entity-popover';

const STUDIO_ROUTES = [
    {
        label: 'Dashboards',
        path: StudioRoutePath.Dashboards(),
        Icon: DashboardIcon,
        isActive: (asPath: string) =>
            asPath === StudioRoutePath.Dashboards() || asPath.startsWith('/dashboards') || asPath.startsWith('/?'),
        isDisabled: (route: string) => route === StudioRoutePath.Dashboards(),
    },
    {
        label: 'Queries',
        path: StudioRoutePath.Queries(),
        Icon: CodeIcon,
        isActive: (asPath: string) => asPath.startsWith(StudioRoutePath.Queries()),
        isDisabled: (route: string) => route === StudioRoutePath.Queries(),
    },
    {
        label: 'Visualizations',
        path: StudioRoutePath.Visualizations(),
        Icon: QueriesIcon,
        isActive: (asPath: string) => asPath.startsWith(StudioRoutePath.Visualizations()),
        isDisabled: (route: string) => route === StudioRoutePath.Visualizations(),
    },
];

const TopNav = () => {
    const { asPath, route } = useRouter();

    return (
        <Bit.div display="flex" w="full" justifyContent="between" alignItems="center">
            <FlexBox gap={2} alignItems="center">
                <Menu orientation="horizontal">
                    <Menu.List>
                        {STUDIO_ROUTES.map(({ label, path, Icon, isActive, isDisabled }) => {
                            const active = isActive(asPath);
                            return (
                                <Menu.Item
                                    key={label}
                                    active={active}
                                    /**
                                     * (Be careful if you decide to remove this)
                                     * this is a superficial fix for disabling active menu items:
                                     * clicking a nav link that is already selected causes query param state bugs.
                                     * @see filters DashboardFilters, QueryFilters, VisualizationFilters
                                     * We should debug and fix this in the near future.
                                     */
                                    href={isDisabled(route) ? undefined : path}
                                    variant="link"
                                    icon={Icon}
                                >
                                    {label}
                                </Menu.Item>
                            );
                        })}
                    </Menu.List>
                </Menu>
                <CreateEntityPopover />
            </FlexBox>
            <UserDropdown />
        </Bit.div>
    );
};

export { TopNav };
