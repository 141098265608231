import { defineEndpoints } from '../../../config/define-endpoints';
import type { DataWithMeta, OrSymbol, PaginatedRequest } from '../../../config/type-helpers';
import type { StudioVisualizationType, StudioVizConfig } from '../visualizations/models';

import { LayoutConfig } from './dashboard-layout.model';

enum StudioDashboardStatus {
    Hidden = 'hidden',
    Draft = 'draft',
    Published = 'published',
}

type DashboardVisualizations<T extends StudioVisualizationType = StudioVisualizationType, S extends boolean = false> = {
    id: string;
    type: T;
    title: string;
    description: string;
    config: S extends true ? StudioVizConfig<T> : string;
    queryId: string;
    querySlug: string;
    lastExecutionId: string;
    executionState: string;
    executionMetadata: string;
    executionStartedAt: string;
    executionEndedAt: string;
    /** @todo add to response in backend */
    subTitle: string;
}[];

type DashboardShape<T extends StudioVisualizationType = StudioVisualizationType, S extends boolean = true> = {
    id: string;
    slug: string;
    userId: string;
    assetId: string;
    title: string;
    description: string;
    status: StudioDashboardStatus;
    count: number;
    updatedAt: number;
    createdAt: number;
    assetTitle: string;
    assetCode: string;
    assetImageUrl: string;
    layoutConfig: string;
    userEmail: string;
    userName: string;
    visualizations: DashboardVisualizations<T, S>;
};

type ParsedDashboardShape = Omit<DashboardShape, 'layoutConfig' | 'visualizations'> & {
    layoutConfig: LayoutConfig;
    visualizations: DashboardVisualizations<any, true>;
};

type GetDashboardRequest = {
    id: string;
    filter?: string;
};

type GetDashboardResponseRaw = DataWithMeta<DashboardShape>;
type GetDashboardResponse = DataWithMeta<ParsedDashboardShape>;

type GetDashboardsResponse = DataWithMeta<DashboardShape[]>;
type GetDashboardsRequest = PaginatedRequest<
    Pick<DashboardShape, 'updatedAt' | 'createdAt' | 'title'>,
    {
        /** filters for dashboards by user */
        userIds?: string[];
        /** filters dashboard by a given string (e.g. title string match) */
        filter?: string;
        /** filters for dashboards that don't have any visualizations.
         * This is a boolean value, but we need to send it as a number to the backend.
         * You can use a boolean value freely in client code; the endpoint fetcher will convert it to a number.
         * `1` is true, `0` is false
         */
        empty?: boolean | 0 | 1;
        status?: OrSymbol<string[] | string>;
    }
>;

enum DashboardFilterKey {
    UserIds = 'userIds',
    Empty = 'empty',
    Mine = 'mine',
    All = 'all',
    Filter = 'filter',
    Status = 'status',
    Order = 'order',
}

type PostCreateDashboardRequest = {
    title: string;
    userId?: string;
    status: StudioDashboardStatus;
    layoutConfig?: LayoutConfig;
    visualizationIds?: string[];
    /** @todo `null` doesn't work as expected. Only using assetId "0" works to reset the asset for a dashboard */
} & { assetId: string | null; imageUrl?: string };

type PostCreateDashboardResponse = DataWithMeta<{
    message: string;
    id: string;
    slug: string;
}>;

type PatchUpdateDashboardRequest = Partial<Omit<PostCreateDashboardRequest, 'userId'>> & { id: string };
type PatchUpdateDashboardResponse = PostCreateDashboardResponse;

type DeleteDashboardResponse = DataWithMeta<{ response: string; status: boolean }>;
type DeleteDashboardRequest = { id: string };

const STUDIO_DASHBOARD_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        DASHBOARD: template('/api/v1/studio/dashboard/{id}'),
        DASHBOARDS: template('/api/v1/studio/dashboards'),
        REFRESH_DASHBOARD_QUERIES: '/api/refresh-dashboard-queries/{id}',
    },
    POST: {
        CREATE_DASHBOARD: template('/api/v1/studio/dashboard'),
    },
    PATCH: {
        UPDATE_DASHBOARD: template('/api/v1/studio/dashboard/{id}'),
    },
    DELETE: {
        DASHBOARD: template('/api/v1/studio/dashboard/{id}'),
    },
}));

const NO_ASSET_CONFIG = {
    value: 'NO_ASSET',
    reset: null,
    imageUrl: '/dashboard-without-asset-icon.png',
};

export type {
    DeleteDashboardRequest,
    DeleteDashboardResponse,
    GetDashboardRequest,
    GetDashboardResponse,
    GetDashboardResponseRaw,
    GetDashboardsRequest,
    GetDashboardsResponse,
    PatchUpdateDashboardRequest,
    PatchUpdateDashboardResponse,
    PostCreateDashboardRequest,
    PostCreateDashboardResponse,
};
export { DashboardFilterKey, NO_ASSET_CONFIG, STUDIO_DASHBOARD_ENDPOINTS, StudioDashboardStatus };
