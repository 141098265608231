import type { GetExecutionResponse } from '../../execution/execution.model';
import { StudioVisualizationType, type StudioVizConfig } from '../models';

type IncludesIdx<T, K> = K extends keyof T ? T[K] : never;
type IsString<T> = T extends string ? T : never;
type InferModel<T extends ReadonlyArray<any>, Y extends ReadonlyArray<any>> = {
    [K in keyof T as `${IsString<T[K]>}`]: IncludesIdx<Y, K>;
};

const makeChartConfig = <T extends GetExecutionResponse['data']>(execution: T): StudioVizConfig<any> => {
    const { columnKeys: columns, columnTypes: types } = execution.metadata ?? {};
    const xColumn = columns?.filter(column => types?.[column]?.includes('date'))[0];

    return {
        type: StudioVisualizationType.Timeseries,
        options: {
            legend: {
                position: 'right',
            },
            brush: false,
            xAxis: {
                logarithmic: false,
                column: xColumn as keyof InferModel<
                    T['metadata']['columnKeys'],
                    T['metadata']['columnTypes'][keyof T['metadata']['columnTypes']][]
                >,
                format: 'monthly',
            },
            groups: [],
        },
        features: {
            export: true,
        },
        parameters: {},
    };
};

export { makeChartConfig };
