import * as React from 'react';
import mixpanelPlugin from '@analytics/mixpanel';
import { Analytics as NextAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

import { AnalyticsProvider, usePageView } from '@blockworks/platform/services/analytics';
import { AnyObj } from '@blockworks/platform/typescript';

type AnalyticsProps = React.PropsWithChildren<{
    meta: AnyObj | null;
}>;

const registerPlugins = (includeClientPlugins: boolean) => {
    const plugins = [];
    // Add frontend plugins only during frontend rendering
    if (includeClientPlugins) {
        if (process.env.MIXPANEL_API_KEY) {
            plugins.push(
                mixpanelPlugin({
                    pageEvent: 'Page View',
                    token: process.env.MIXPANEL_API_KEY,
                    customScriptSrc: `/mp/lib.min.js`,
                    options: {
                        api_host: `/mp`,
                        persistence: 'localStorage',
                        verbose: process.env.NODE_ENV === 'development',
                        debug: process.env.NODE_ENV === 'development',
                    },
                }),
            );
        }
    }
    return plugins;
};

const PageView = ({ meta }: Pick<AnalyticsProps, 'meta'>) => {
    usePageView({ meta });
    return null;
};

const Analytics = ({ children, meta }: AnalyticsProps) => {
    return (
        <AnalyticsProvider app="blockworks-research" version="1.0.0" registerPlugins={registerPlugins}>
            {children}
            <PageView meta={meta} />
            <NextAnalytics />
            <SpeedInsights />
        </AnalyticsProvider>
    );
};

export { Analytics };
