import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { SessionUser } from 'next-auth';
import { useSession } from 'next-auth/react';

import { mapUserToAnalyticsUser, useIdentify, useReset } from '@blockworks/platform/services/analytics';

const IdentifyAnalyticsUser = () => {
    const session = useSession();
    const identify = useIdentify();
    const reset = useReset();
    const [identifiedUser, setIdentifiedUser] = useState<SessionUser | null>(null);
    const { data } = session;
    const user = data?.user;

    useEffect(() => {
        const isInternalUser = user?.email?.includes('@blockworks.co') ?? false;

        if (identifiedUser === null && user !== null && user?.id) {
            setIdentifiedUser(user);
            identify?.(String(user.id), mapUserToAnalyticsUser(user));
            Sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.name,
            });

            Sentry.setTag('internal', isInternalUser);
        } else if (identifiedUser !== null && user === null) {
            setIdentifiedUser(null);
            reset?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, identify, reset]);

    return null;
};

export { IdentifyAnalyticsUser };
