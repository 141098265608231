import { BASE_URLS } from '../../../config/base-urls';
import { createApi } from '../../../config/create-api';
import { formatOrderParam } from '../../../config/format-order-param';
import { STUDIO_HEADERS } from '../config/headers';

import {
    DeleteDashboardRequest,
    DeleteDashboardResponse,
    GetDashboardRequest,
    GetDashboardResponse,
    GetDashboardResponseRaw,
    GetDashboardsRequest,
    GetDashboardsResponse,
    PatchUpdateDashboardRequest,
    PatchUpdateDashboardResponse,
    PostCreateDashboardRequest,
    PostCreateDashboardResponse,
    STUDIO_DASHBOARD_ENDPOINTS,
} from './dashboard.model';
import { getVizIdsFromLayout, parseDashboardVisualizationConfigs } from './dashboard.utils';
import { DEFAULT_LAYOUT_CONFIG } from './dashboard-layout.model';
import { getSafeLayoutConfig } from './dashboard-layout.utils';

const studioDashboardApi = createApi(build => ({
    get: {
        dashboard: build<GetDashboardResponse, GetDashboardRequest>({
            cachePrefix: 'dashboard-by-id',
            queryFn: async ({ id }, { baseFetch }) => {
                try {
                    const res = await baseFetch<false, any, GetDashboardResponseRaw>({
                        url: `${BASE_URLS['research-api']}${STUDIO_DASHBOARD_ENDPOINTS.GET.DASHBOARD({ id })}`,
                        headers: STUDIO_HEADERS,
                    });

                    return {
                        ...res,
                        data: {
                            ...res.data,
                            layoutConfig: getSafeLayoutConfig(res?.data?.layoutConfig),
                            visualizations: parseDashboardVisualizationConfigs(res?.data?.visualizations),
                        },
                    };
                } catch (error) {
                    const err = error as Error;
                    console.error(err);

                    return { data: undefined as any, _meta: { total: 0, success: false, message: err?.message } };
                }
            },
        }),
        dashboards: build<GetDashboardsResponse, GetDashboardsRequest>({
            cachePrefix: 'dashboards',
            query: ({ userIds, filter, order, empty, status } = {}) => {
                return {
                    uri: STUDIO_DASHBOARD_ENDPOINTS.GET.DASHBOARDS(),
                    params: {
                        userIds,
                        filter,
                        order: formatOrderParam(order),
                        empty: empty ? 1 : undefined,
                        status,
                    },
                    options: {
                        headers: STUDIO_HEADERS,
                    },
                };
            },
        }),
    },
    patch: {
        updateDashboard: build<PatchUpdateDashboardResponse, PatchUpdateDashboardRequest>({
            cachePrefix: 'update-dashboard',
            query: ({ id, layoutConfig, visualizationIds, ...restBody }) => {
                return {
                    uri: STUDIO_DASHBOARD_ENDPOINTS.PATCH.UPDATE_DASHBOARD({ id }),
                    options: {
                        headers: STUDIO_HEADERS,
                        method: build.Method.Patch,
                        body: {
                            layoutConfig: JSON.stringify(layoutConfig),
                            visualizationIds: getVizIdsFromLayout(layoutConfig),
                            ...restBody,
                        },
                    },
                };
            },
        }),
    },
    post: {
        createDashboard: build<PostCreateDashboardResponse, PostCreateDashboardRequest>({
            cachePrefix: 'create-dashboard',
            query: ({ layoutConfig = DEFAULT_LAYOUT_CONFIG, ...body }) => {
                return {
                    uri: STUDIO_DASHBOARD_ENDPOINTS.POST.CREATE_DASHBOARD(),
                    options: {
                        method: build.Method.Post,
                        headers: STUDIO_HEADERS,
                        body: {
                            ...body,
                            layoutConfig: JSON.stringify(layoutConfig),
                        },
                    },
                };
            },
        }),
    },
    delete: {
        dashboard: build<DeleteDashboardResponse, DeleteDashboardRequest>({
            cachePrefix: 'delete-dashboard',
            query: ({ id }) => {
                return {
                    uri: STUDIO_DASHBOARD_ENDPOINTS.DELETE.DASHBOARD({ id }),
                    options: {
                        headers: STUDIO_HEADERS,
                        method: build.Method.Delete,
                    },
                };
            },
        }),
    },
}));

export { studioDashboardApi };
