import { sortBy, sortedUniqBy, uniqBy } from 'lodash';

import { GetAlgoliaStudioTableResponse } from '@blockworks/platform/api/algolia';
import { GetQueriesResponse } from '@blockworks/platform/api/research/studio/queries';

const selectTableSchema = (res: GetAlgoliaStudioTableResponse) => {
    const tables: Record<string, Record<string, string[]>> = {};

    res.hits?.forEach(entry => {
        const schema = entry.tableSchema;
        const tableName = entry.tableName;
        const columns = entry.tableColumns;

        if (!tables[schema]) {
            tables[schema] = {};
        }

        tables[schema][tableName] = columns;
    });

    return tables;
};

const selectTables = (res: GetAlgoliaStudioTableResponse) => {
    if (!Array.isArray(res.hits)) {
        return { items: [], totalCount: 0 };
    }
    const tables = res.hits.reduce(
        (acc, { tableSchema, tableName }) => {
            if (tableSchema && tableName) {
                acc.push({
                    tableName: `${tableSchema}.${tableName}`,
                    tableSchema,
                });
            }
            return acc;
        },
        [] as Array<{ tableName: string; tableSchema: string }>,
    );
    return {
        items: tables,
        totalCount: tables.length,
    };
};

/** @todo move to common selectors and implement for dashboard filters + v */
const selectUniqueCreators =
    (exclude?: string[]) =>
    ({ data }: GetQueriesResponse) => {
        let creators = uniqBy(
            data.map(({ userId, userName }) => ({ label: userName, value: userId })),
            'value',
        );

        if (exclude?.length) {
            creators = creators.filter(({ value }) => !exclude.includes(value));
        }

        /** @todo remove duplicated labels? dennis has 2 users with the same name (id 1, id 37) */
        return sortedUniqBy(sortBy(creators, 'label'), 'value');
    };

export { selectTables, selectTableSchema, selectUniqueCreators };
