import { createApi } from '../../../config/create-api';
import { formatOrderParam } from '../../../config/format-order-param';
import { STUDIO_HEADERS } from '../config/headers';

import {
    DeleteQueryRequest,
    DeleteQueryResponse,
    GetQueriesRequest,
    GetQueriesResponse,
    GetQueryRequest,
    GetQueryResponse,
    PatchUpdateQueryRequest,
    PostCreateQueryRequest,
    PostCreateQueryResponse,
    STUDIO_QUERIES_ENDPOINTS,
} from './queries.model';

const studioQueriesApi = createApi(build => ({
    get: {
        query: build<GetQueryResponse, GetQueryRequest>({
            cachePrefix: 'query-by-id',
            query: ({ id }) => {
                return {
                    uri: STUDIO_QUERIES_ENDPOINTS.GET.QUERY({ id }),
                    options: {
                        headers: STUDIO_HEADERS,
                    },
                };
            },
        }),
        queries: build<GetQueriesResponse, GetQueriesRequest>({
            cachePrefix: 'all-queries',
            query: ({ userIds, dashboardIds, states, order = 'updatedAt,desc', filter, page = 1, limit = 1000 }) => {
                return {
                    uri: STUDIO_QUERIES_ENDPOINTS.GET.QUERIES(),
                    options: {
                        headers: STUDIO_HEADERS,
                    },
                    params: {
                        userIds,
                        dashboardIds,
                        states,
                        order: formatOrderParam(order),
                        page,
                        filter,
                        limit,
                    },
                };
            },
        }),
    },
    patch: {
        updateQuery: build<any, PatchUpdateQueryRequest>({
            cachePrefix: 'update-query',
            query: ({ id, ...body }) => {
                return {
                    uri: STUDIO_QUERIES_ENDPOINTS.PATCH.QUERY({ id }),
                    options: {
                        headers: STUDIO_HEADERS,
                        method: build.Method.Patch,
                        body,
                    },
                };
            },
        }),
    },
    post: {
        createQuery: build<PostCreateQueryResponse, PostCreateQueryRequest>({
            cachePrefix: 'create-query',
            query: ({ title, sql, userId }) => {
                return {
                    uri: STUDIO_QUERIES_ENDPOINTS.POST.QUERY(),
                    options: {
                        headers: STUDIO_HEADERS,
                        method: build.Method.Post,
                        body: { title, sql, userId },
                    },
                };
            },
        }),
    },
    delete: {
        query: build<DeleteQueryResponse, DeleteQueryRequest>({
            cachePrefix: 'delete-query',
            query: ({ id }) => {
                return {
                    uri: STUDIO_QUERIES_ENDPOINTS.DELETE.QUERY({ id }),
                    options: {
                        headers: STUDIO_HEADERS,
                        method: build.Method.Delete,
                    },
                };
            },
        }),
    },
}));

export { studioQueriesApi };
